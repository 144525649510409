@import "./normalize";
@import "./variables";
@import "./fonts";

body {
  font-family: "Montserrat", sans-serif;
}
html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el root tenga al menos la altura de la ventana */
}

main {
  flex: 1; /* Permite que el contenido principal se expanda para llenar el espacio disponible */
}