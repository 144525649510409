.cart-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .cart-page__items {
    display: flex;
    flex-direction: column;
  }
  
  .cart-page__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  