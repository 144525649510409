.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  height: 80px;
  padding: 0 1rem;
  background-color: #5fa801; /* Cambiado a #5fa801 directamente */
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 60%);

  &__logo {
    width: 85px;
    position: relative;
    z-index: 3;
    text-decoration: none;
    font-family: "Lobster", cursive;
    font-size: 25px;
    color: white;
  }

  &__toggle {
    cursor: pointer;
    border: none;
    width: 40px;
    height: 30px;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;

    span {
      width: 100%;
      height: 5px;
      border-radius: 1px;
      display: block;
      position: absolute;
      background-color: white;
      transition: all 1s ease;
      transform-origin: 0px 100%;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }
  }

  &__nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    pointer-events: none;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #5fa801; /* Cambiado a #5fa801 */
    padding-right: 1rem;
    transition: all 800ms ease-in-out;

    &__navlinks {
      margin-top: 4rem;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      gap: 35px;
      width: 100%;
      height: auto;
      padding-left: 1.5rem;
    }

    &__rrsslinks {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 45px;
      width: 100%;
      height: 20%;
    }
  }

  &__navlink {
    a {
      text-decoration: none;
      text-transform: uppercase;
      color: white; /* Cambiado a blanco para contraste */
      font-weight: 500;
      font-size: 20px;
      transition: border 800ms ease-in-out;

      &:hover,
      &:focus,
      &:active {
        border-bottom: 1px solid white; /* Cambiado a blanco */
      }
    }
  }

  &__login {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      text-decoration: none;
      background-color: white;
      color: #5fa801;
      text-transform: capitalize;
      font-weight: 300;
      padding: 0.5rem 2rem;
      border-radius: 5px;
    }
  }

  &__rrsslink {
    a {
      img {
        width: auto;
        height: 40px;
      }
    }
  }
}

.show-menu {
  .header {
    &__toggle {
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(0px, -5px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(5px, 5px);
        }
      }
    }

    &__nav {
      right: 0;
      pointer-events: all;
    }
  }
}

.header__cart {
  display: flex;
  align-items: center;
}

.header__cart-icon {
  margin-top: -6px;
  color: white;
}

@media (max-width: 1024px) {
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    gap: 10px;

    &__toggle {
      order: -1;
      width: 30px;
      height: 25px;
    }

    &__logo {
      width: 75px;
      margin-left: 0;
      img {
        width: 100%;
        height: auto;
      }
    }

    &__cart {
      order: 2; /* El carrito al lado del logo */
      margin-left: 0; /* Sin margen izquierdo, para que esté junto al logo */
    }

    &__login {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto; /* Mantiene el botón de login alineado a la derecha */
    }
  }
}

@media (min-width: 1024px) {
  .header {
    gap: 30px;

    &__cart {
      order: 0; /* El carrito queda antes del login */
      margin-right: 20px; /* Espacio entre carrito y login */
    }

    &__toggle {
      display: none;
    }

    &__nav {
      position: static;
      pointer-events: all;
      background-color: #5fa801; /* Cambiado a #5fa801 */
      height: auto;
      width: auto;
      flex-direction: row; /* Los elementos del nav ahora están en fila */
      gap: 25px;
      padding-right: 0;

      &__navlinks {
        align-items: center;
        flex-direction: row; /* Cambia la dirección a horizontal */
        padding: 0;
        margin: 0;

        a {
          font-size: 14px;
          margin-bottom: 0; /* Elimina margen inferior en pantallas grandes */
        }
      }

      &__rrsslinks {
        align-items: center;
        flex-direction: row;
        padding: 0;
        order: 2;
        gap: 15px;
      }
    }

    &__login {
      order: 3; /* Mantiene el login después del carrito */
    }

    &__rrsslink {
      a {
        img {
          height: 20px;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .header {
    &__nav {
      gap: 50px;

      &__rrsslinks {
        gap: 20px;
      }
    }

    &__rrsslink {
      a {
        img {
          height: 25px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .header {
    padding: 0 4rem;
  }
}
