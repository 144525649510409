/* CartIcon.scss */
.header__cart {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .header__cart img {
    width: 24px; /* Ajusta el tamaño del ícono según sea necesario */
    height: 24px;
    filter: invert(1); /* Convierte el ícono a blanco */
  }
  
  .header__cart-count {
    position: absolute;
    top: -10px; /* Ajusta la posición vertical del contador */
    right: -10px; /* Ajusta la posición horizontal del contador */
    background-color: red; /* Cambia el color de fondo si es necesario */
    color: white; /* Asegúrate de que el texto sea blanco */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Ajusta el tamaño de la fuente del contador */
  }

  .cart-icon-badge {
    .MuiBadge-badge {
      right: 0; // Ajusta la posición horizontal si es necesario
      top: 0; // Ajusta la posición vertical si es necesario
      height: 15px; // Ajusta la altura del badge
      width: 15px; // Ajusta el ancho del badge
      font-size: 10px; // Ajusta el tamaño del texto en el badge
      border-radius: 50%; // Asegura que el badge sea redondo
    }
  }
  