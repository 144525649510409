.main {
  position: relative;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    font-weight: 500;
  }

  .section--steps,
  .section--banner-cards,
  .section--other-products {
    width: 70%; /* Usa el 80% del ancho de la página */
    margin: 0 auto; /* Centra el contenido */
  }

  .section--steps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0;
    margin: 1rem auto;

    h2 {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }

    .steps {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      margin: 1rem 0;
      width: 100%;

      &__card {
        flex: 1 1 0;
        background-color: var(--primary-color);
        color: var(--text-primary-color);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        border-radius: 5px;

        &--title {
          position: relative;
          width: 100%;
          padding: 1rem 0.5rem;
          background-color: white;
          font-family: "Lobster", cursive;
          font-size: 40px;
          color: black;
          text-align: center;
          transition: background 0.5s;

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 100%;
            z-index: 1;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            border-top: 20px solid #fff;
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
            transition: 0.5s;
          }

          &:hover {
            background: #ec9d2a;
          }

          &:hover::before {
            border-top: 20px solid #ec9d2a;
          }
        }

        &--description {
          padding: 2rem 1rem;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  .section--banner-cards {
    margin: 3rem auto;

    .banner-cards {
      width: 100%;
      margin: auto;
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap; /* Evita que las tarjetas se envuelvan */
      gap: 1rem;

      .banner-card {
        position: relative;
        flex: 1 1 0; /* Permite que las tarjetas ocupen el mismo espacio */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px; /* Agrega algo de padding */
        box-sizing: border-box;

        p {
          position: absolute;
          left: 0;
          right: 0;
          top: 10%;
          bottom: auto;
          margin: auto;
          width: 100%;
          text-align: center;
          color: white;
          font-weight: 700;
          z-index: 1;
        }

        img {
          width: 100%;
          height: auto; /* Mantiene la proporción de la imagen */
          object-fit: cover; /* Hace que la imagen se ajuste al tamaño del contenedor */
          border-radius: 5px; /* Opcional: redondear bordes */
        }
      }
    }
  }

  .section--other-products {
    margin: 3rem auto;

    .banner-cards {
      width: 100%;
      margin: auto;
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;

      .banner-card {
        position: relative;

        p {
          position: absolute;
          left: 0;
          right: 0;
          top: 10%;
          bottom: auto;
          margin: auto;
          width: 100%;
          text-align: center;
          color: white;
          font-weight: 700;
        }

        img {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }

  .local-image-section {
    text-align: center; /* Centrar la imagen */
    margin: 2rem 0; /* Agregar márgenes */
  }
  
  .local-image {
    max-width: 80%; /* Asegura que la imagen no sea más grande que su contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 10px; /* Ejemplo de bordes redondeados */
  }


  @media (max-width: 767px) {
    .section--banner-cards {
      .banner-cards {
        display: grid; /* Utilizar grid para controlar mejor la distribución */
        grid-template-columns: repeat(2, 1fr); /* Configurar dos columnas de igual tamaño */
        gap: 1rem; /* Espacio entre las tarjetas */
  
        li {
          width: 100%; /* Cada tarjeta ocupará el ancho completo de su contenedor */
        }
  
        .banner-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          
          img {
            width: 100%; /* Asegura que la imagen ocupe el ancho del contenedor */
            height: auto; /* Mantener proporción */
            border-radius: 5px; /* Opcional: agregar borde redondeado */
          }
  
          p {
            margin-top: 0.5rem; /* Espacio entre el texto y la imagen */
            font-size: 14px; /* Ajustar tamaño de fuente para vista móvil */
          }
        }
      }
    }
  }
  
}



